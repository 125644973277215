import React from "react";
import { useEffect, useState } from "react";
import "./select.css";

export default function Select({ defaultOption, options, onOptionChange = () => {}, className }) {
  const [isSelectMenuVisible, setIsSelectMenuVisibile] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    if (selectedOption !== "") {
      onOptionChange(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <div className={`select-container ${className ?? ""}`}>
      <div className="select-trigger" onClick={() => setIsSelectMenuVisibile(!isSelectMenuVisible)}>
        <div className="select-label">
          <span>{selectedOption.label}</span>
          <svg
            className={`select-arrow ${isSelectMenuVisible ? "rotated" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="black">
            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
          </svg>
        </div>
        {isSelectMenuVisible && (
          <div className="select-dropdown">
            {options.map((option) => {
              if (option === selectedOption) return null;
              return (
                <div
                  className="select-option"
                  key={option.label}
                  onClick={() => {
                    setSelectedOption(option);
                    setIsSelectMenuVisibile(false);
                  }}>
                  <span>{option.label}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
