import React, { useEffect } from 'react';
import MovieSchedule from './MovieSchedule';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Import your CSS for styling
import './assets/fonts/bogueslab-semibold.otf';

import logo from './assets/eventspire.png'; // Adjust the path based on your file structure

const App = () => {
  useEffect(() => {
    document.title = "eventspire";

    // Dynamically add the Plausible Analytics script
    const script = document.createElement("script");
    script.setAttribute("defer", "");
    script.setAttribute("data-domain", "eventspire.nl");
    script.setAttribute("data-api", "/api/event"); // Add the data-api attribute
    script.setAttribute("src", "/js/script.js"); // Use the proxied endpoint

    document.head.appendChild(script);

    // Cleanup function to remove the script if needed
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="app-header">
        <div className='header-items--container'>
          <Link to="/">
            <img src={logo} alt="Eventspire Logo" className="app-logo" />
          </Link>
          <div className='nav-links'>
            <a
                href="https://www.buymeacoffee.com/manfredi"
                className="coffee-button"
                onClick={(e) => {
                  window.plausible('ButtonClick'); // Custom event name
                  setTimeout(() => {
                    window.location.href = 'https://www.buymeacoffee.com/manfredi';
                  }, 300); // Optional delay to ensure the event is tracked
                  e.preventDefault(); // Prevent the default action to allow tracking before redirect
                }}
            >
              Keep the server up
            </a>
          </div>
          </div>
        </header>
        <Routes>
          <Route path="/" element={<MovieSchedule />} />
          {/* Define other routes here if needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
